<template>
	<cam-box p="2" class="el-contact">
		<cam-box class="contact" flex="center" dir="column" p="8">
			<i class="lni lni-envelope" style="font-size: 3em"></i>
			<p align="center"> Have a problem? Have a comment? <br>Shoot us an email. Our messenger may be able to to help.</p>
			<a href="mailto:paul.revere@ellis-project.org"><span>paul.revere@ellis-project.org</span></a>
			<p>To stay up to date with new Ellis features, follow the Ellis Project on social media.</p>
			<div class="social">
				<a href="https://www.facebook.com/ellisproj" target="_blank" title="Facebook"><i class="lni lni-facebook"/></a>
				<a href="https://twitter.com/project_ellis" target="_blank" title="Twitter"><i class="lni lni-twitter"/></a>
			</div>
		</cam-box>
	</cam-box>
</template>

<script lang="ts">
export default {
	name: 'el-contact',
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.contact {
	& > i.lni {
		color: lighten($c-bg, 70%)
	}
	.social {
		flexXY(center)
		& > * {
			flexXY(center)
			neu(#e7e8ea, 1)
			margin: 16px !important
			font-size: 1.4em
			circle(2.4em)
			transition: 0.3s all ease
			&:hover {
				neu(#f6f7f8, 0.5)
			}
		}
	}
}
</style>